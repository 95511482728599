"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";


import errorImg from "@/Helper/images/errorPage/error500.svg";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import "@/app/error.css";
export default function Error505() {
  const t = useTranslations("NotFoundPage");
  const { locale } = useRouter();
  return (
    <div className="error_container">
      <Image src={errorImg} width={585} height={321} alt="error" />
      <h1 className="error_heading">{t("title")}</h1>
      <p className="error_subheading">{t("description")}</p>
      <div className="error_btn_area notfound_button">
        <Link href={"/"}>
          <button className="redirecthome">{t("backhome")}</button>
        </Link>
        <Link href={`/en/contact-us`}>
          <button className="reportbutton">{t("reportproblem")}</button>
        </Link>
      </div>
    </div>
  );
}
